<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        card
      >
        <v-toolbar-items>
          <v-spacer />
          <v-text-field
            v-model="searchAccount"
            label="Enter value"
            placeholder="MSISDN"
            clearable
          />
          <v-btn
            flat
            @click="searchByMsisdn"
          >
            by MSISDN
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-expansion-panel
        popout
        focusable
      >
        <v-expansion-panel-content>
          <template #header>
            <div>Profile</div>
          </template>
          <v-card>
            <v-card-text>
              <v-container grid-list-xs>
                <v-layout
                  row
                  wrap
                >
                  <template v-for="field in self">
                    <v-flex
                      :key="field.subject"
                      xs4
                    >
                      <span class="primary--text">{{ field.subject }}</span>
                    </v-flex>
                    <v-flex
                      :key="field.subject + 'value'"
                      xs8
                    >
                      {{ field.value }}
                    </v-flex>
                  </template>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <template #header>
            <div>Activities</div>
          </template>
          <v-card>
            <v-toolbar
              dense
              card
            >
              <v-menu
                v-model="menu"
                close-on-content-click
                transition="scale-transition"
                full-width
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="monthOf"
                  prepend-icon="event"
                  readonly
                />
                <v-date-picker
                  v-model="monthOf"
                  :max="maxDate"
                  :allowed-dates="allowedMonths"
                  min="2018-01"
                  type="month"
                  year-icon="date_range"
                />
              </v-menu>
              <v-spacer />
            </v-toolbar>
            <v-card-title>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                solo-inverted
                clearable
                box
                hide-details
              />
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="data"
              :search="search"
              :rows-per-page-items="[10, 25, 50, 100]"
              :expand="true"
              item-key="entry"
              class="px-1 pb-1"
            >
              <template #items="props">
                <tr @click="props.expanded = !props.expanded">
                  <td class="text-xs-left">
                    {{ props.item.entry }}
                  </td>
                  <td class="text-xs-left">
                    {{ props.item.action }}
                  </td>
                  <td class="text-xs-left">
                    {{ props.item.target }}
                  </td>
                  <td class="text-xs-left">
                    {{ props.item.phase }}
                  </td>
                  <td
                    v-if="props.item.status === 'Success'"
                    class="text-xs-left success--text"
                  >
                    {{ props.item.status }}
                  </td>
                  <td
                    v-else
                    class="text-xs-left error--text"
                  >
                    {{ props.item.status }}
                  </td>
                  <td class="text-xs-left">
                    {{ props.item.remark }}
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                <v-alert
                  :value="true"
                  :type="error ? 'error' : 'info'"
                >
                  {{ noDataText }}
                </v-alert>
              </template>
            </v-data-table>
          </v-card>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <template #header>
            <div>Image</div>
          </template>
          <div class="d-flex justify-space-around align-center bg-grey-lighten-4">
            <div class="ma-4">
              <div class="text-subtitle-2">
                MyKad
              </div>
              <v-img
                height="250"
                :src="nricImage"
                :aspect-ratio="16/9"
                max-width="400"
              />
            </div>
          </div>
          <div class="d-flex justify-space-around align-center bg-grey-lighten-4">
            <div class="ma-4">
              <div class="text-subtitle-2">
                Student card Front
              </div>
              <v-img
                height="500"
                :src="studentFImage"
                max-width="400"
              />
            </div>
            <div class="ma-4">
              <div class="text-subtitle-2">
                Student Card Back
              </div>
              <v-img
                height="500"
                :src="studentBImage"
                max-width="400"
              />
            </div>
          </div>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <template #header>
            <div>Location</div>
          </template>
          <gmap-map
            :zoom="14"
            :center="center"
            style="width:100%; height: 300px"
          >
            <gmap-marker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
            />
          </gmap-map>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-card>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline red lighten-2"
          primary-title
        >
          Error
        </v-card-title>

        <v-card-text>
          {{ responseError }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const pageTitle = 'My Siswa'

export default {
  props: {
    id: {
      required: false,
      type: String,
      default: null,
    },

  },
  components: {
    Panel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: pageTitle, disabled: true,
        },
      ],
      image: 'Nothing to display',
      center: { lat: 3.130762402026431, lng: 101.59494799528905 },
      existingPlace: null,
      nostudentBImage: false,
      nostudentFImage: false,
      error: null,
      responseError: null,
      ImageErrorB: null,
      ImageErrorI: null,
      ImageErrorMyKad: null,
      dialog: false,
      loading: false,
      maxDate: '',
      nricImage: '',
      studentFImage: '',
      studentBImage: '',
      menu: false,
      monthOf: null,
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      search: '',
      searchAccount: null,
      self: null,
      markers: [
      ],
      data: [],
      title: pageTitle,
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'Entry',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: true,
          text: 'MSISDN',
          value: 'target',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Detail',
          value: 'phase',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Status',
          value: 'status',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Remark',
          value: 'remark',
        },
      ],
    }
  },
  created: function () {
    this.getDataResponse()
  },
  watch: {
    monthOf: function (val) {
      this.getActivities(val)
    },
  },
  computed: {
    disabled: function () {
      return this.self.length === 0
    },
  },
  mounted: function () {
    this.searchAccount = this.id
    this.getImage()
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    getDataResponse () {
      const params = createGetParams({
        msisdn: this.id,
      })
      this.loading = true
      this.$rest.get('getMySiswaAccount.php', params)
        .then(function (response) {
          this.self = response.data.self
          this.markers = response.data.maps
          this.center = response.data.center
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
      this.getImage(params)
    },
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getActivities (month) {
      const byMsisdn = this.searchAccount
      if (!byMsisdn) return

      const params = byMsisdn ? createGetParams({
        month: month,
        msisdn: this.searchAccount,
      }) : createGetParams({
        month: month,
        accountNumber: this.searchAccount,
      })

      this.loading = true
      this.$rest.get('getMySiswaActivities.php', params)
        .then(function (response) {
          this.data = response.data.items
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },

    getImage: function () {
      const params = createGetParams({
        msisdn: this.searchAccount,
      })
      this.$rest.get('getImageStudent.php', params)
        .then(function (response) {
          this.nricImage = response.data.nric
          this.studentFImage = response.data.studentF
          this.studentBImage = response.data.studentB
        }.bind(this)).catch(error => {
          this.ImageErrorB = error.message
        })
    },
    searchByMsisdn: function () {
      const params = createGetParams({
        msisdn: this.searchAccount,
      })
      this.searchSubscriberAccount(params)
    },

    searchSubscriberAccount: function (params) {
      this.$rest.get('getMySiswaAccount.php', params)
        .then(function (response) {
          this.self = response.data.self
          this.markers = response.data.maps
          this.center = response.data.center
        }.bind(this)).catch(() => {
          this.responseError = 'Not Found or Not a MySiswa number!'
          this.searchAccount = null
          this.dialog = true
        })

      if (this.dialog) return
      this.getActivities(this.monthOf)
      this.getImage(this.searchAccount)
      let path = '/support/mysiswa/'

      path += this.searchAccount
      this.$router.push({
        path,
      })
    },

  },
}
</script>
